<template>
  <div class="full-line full-height container" @click="bgClick">
    <div class="content_container full-height flex-col align-center" :style="style" @click.stop="stop">
      <div class="scroll_container">
        <cube-scroll ref="scroll" :data="items" :options="options">
          <slot></slot>
        </cube-scroll>
      </div>
      <div class="full-line bottom flex-row align-center">
        <div class="button_container flex-row">
          <div class="full-height flex-row align-center justify-center second-bg-color button white-font-color text-16" @click="reset">重置</div>
          <div class="full-height flex-row align-center justify-center base-bg-color button white-font-color text-16" @click="confirm">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Drawer",
  props: {
    onBgClicked: Function,
    onReset: Function,
    onConfirm: Function,
    direction: {
      type: String,
      default: "h"
    }
  },
  data: function () {
    return {
      width: 375,
      height: 750,
      isShowing: false,
      items: [],
      options: {}
    };
  },
  computed: {
    style: function () {
      if (this.isShowing) {
        return "left:" + this.width * 0.3 + "px;";
      }
      return "left:" + this.width + "px;";
    }
  },
  methods: {
    show: function () {
      this.isShowing = true;
    },
    bgClick: function () {
      this.isShowing = false;
      setTimeout(() => {
        this.onBgClicked();
      }, 300);
    },
    stop: function () { },
    reset: function () {
      this.isShowing = false;
      setTimeout(() => {
        this.onReset();
      }, 300);
    },
    confirm: function () {
      this.isShowing = false;
      setTimeout(() => {
        this.onConfirm();
      }, 300);
    }
  },
  mounted: function () {
    this.width = document.documentElement.clientWidth;
    this.height = document.documentElement.clientHeight;
  }
};
</script>

<style scoped>
.container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.4);
}

.content_container {
  position: relative;
  background-color: white;
  width: 70%;
  transition: 0.3s ease-in-out;
  top: 0;
}
.scroll_container {
  margin-top: 29px;
  flex: 1;
  min-height: 0;
  width: calc(100% - 20px);
}
.bottom {
  flex: 0 0 110px;
  height: 110px;
  justify-content: flex-end;
}
.button_container {
  width: 180px;
  height: 34px;
  border-radius: 17px;
  overflow: hidden;
  margin-right: 10px;
}
.button {
  flex: 1;
}
</style>